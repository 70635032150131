<template>
    <div>
        <!-- Global notification live region, render this permanently at the end of the document -->
        <div aria-live="assertive"
            class="fixed left-0 bottom-5 sm:left-5 flex items-end mx-1 sm:px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50">
            <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
                <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
                <transition v-for="message in messages" :key="generateKey(message)"
                    enter-active-class="transform ease-out duration-300 transition"
                    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                    leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <div v-if="message.show"
                        class="w-[98vw] sm:w-full sm:min-w-[400px] bg-white shadow-lg rounded-lg text-sm sm:text-base pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div class="p-4">
                            <div class="flex items-start">
                                <div v-if="message.type == 'success'" class="flex-shrink-0">
                                    <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                                </div>
                                <div v-if="message.type == 'error'" class="flex-shrink-0">
                                    <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
                                </div>
                                <div v-if="message.type == 'warn'" class="flex-shrink-0">
                                    <ExclamationCircleIcon class="h-6 w-6 text-orange-400" aria-hidden="true" />
                                </div>
                                <div class="ml-3 w-0 flex-1 pt-0.5">
                                    <p class="text-sm font-medium text-gray-900">{{ message.title }}</p>
                                    <p class="mt-1 text-sm text-gray-500">{{ message.content }}</p>
                                </div>
                                <div class="ml-4 flex-shrink-0 flex">
                                    <button @click="message.show = false"
                                        class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span class="sr-only">Close</span>
                                        <XIcon class="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>

        <!-- Router view -->
        <!-- Tailwind: bg-blue-500 bg-green-500 bg-yellow-500 -->
        <router-view />
    </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'

import { useComposition } from '@/compositions/composition'

export default {
    components: {
        CheckCircleIcon,
        XCircleIcon,
        XIcon,
        ExclamationCircleIcon
    },
    setup() {
        const { messages } = useComposition();

        return {
            messages
        }
    },
    methods: {
        generateKey() {
            return Math.floor(Math.random() * 100)
        }
    }
}
</script>

<style>
@import url('https://rsms.me/inter/inter.css');

html,
body {
    height: 100%;
}
</style>
